import React, { useState } from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  Checkbox,
  FormControlLabel,
  Typography,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  useMediaQuery,
  useTheme,
  Grid,
  RadioGroup,
  Radio,
} from "@mui/material";
import { BsArrowUpRight } from "react-icons/bs";
import {
  postSubscription,
  PayloadPostSubscription,
} from "../../services/userAgentService";

const rolesItems = [
  "Business Analyst",
  "Content Creator",
  "Customer Support",
  "Cybersecurity Analyst",
  "Data Scientist",
  "Software Engineer",
  "Educator",
  "Entertainer",
  "Researcher or Scientist",
  "Financial Advisor",
  "Government Employee",
  "Healthcare Professional",
  "Human Resources Professional",
  "Journalist",
  "Legal Professional",
  "Manufacturing Engineer",
  "Marketing or Sales",
  "Operations Manager",
  "Product Manager",
  "Quality Control Specialist",
  "Real Estate Professional",
  "Risk Analyst",
  "Supply Chain",
  "Other",
];

const CallToActions: React.FC<any> = ({ handleGoBtnClick, sessionId }) => {
  const [email, setEmail] = useState<string>("");
  const [isChecked, setIsChecked] = useState<boolean>(true);
  const [emailError, setEmailError] = useState<boolean>(false);
  const [selectedRole, setSelectedRole] = useState<string>("");
  const [roleError, setRoleError] = useState<boolean>(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [success, setSuccess] = useState<boolean>(false);
  const [answers, setAnswers] = useState({
    question1: "",
    question2: "",
    question3: "",
    question4: "",
    question5: "",
  });

  const [questionErrors, setQuestionErrors] = useState({
    question1: false,
    question2: false,
    question3: false,
    question4: false,
    question5: false,
  });

  const validateEmail = (email: string) => {
    const emailPattern = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    return emailPattern.test(email);
  };

  const handleEmailChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const value = e.target.value;
    setEmail(value);
    setEmailError(false);
  };

  const handleRoleChange = (e: any) => {
    setSelectedRole(e.target.value as string);
    setRoleError(false);
  };

  const handleSubmit = async () => {
    const newQuestionErrors = {
      question1: !answers.question1,
      question2: !answers.question2,
      question3: !answers.question3,
      question4: !answers.question4,
      question5: !answers.question5,
    };

    setQuestionErrors(newQuestionErrors);

    if (
      validateEmail(email) &&
      selectedRole &&
      !Object.values(newQuestionErrors).includes(true)
    ) {
      setEmailError(false);
      setRoleError(false);
      handleGoBtnClick();
      const payload: PayloadPostSubscription = {
        email_id: email,
        is_newsletter_subscribed: isChecked,
        session_id: sessionId,
        profession: selectedRole,
        ...answers,
      };

      setLoading(true);
      setError(null);
      try {
        const response = await postSubscription(payload);
        setSuccess(true);
      } catch (error) {
        setError("Failed to update user agents");
      } finally {
        setLoading(false);
      }
    } else {
      if (!selectedRole) {
        setRoleError(true);
      }
      if (!validateEmail(email)) {
        setEmailError(true);
      }
    }
  };

  const handleRadioChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    question: string
  ) => {
    setAnswers((prevState) => ({
      ...prevState,
      [question]: event.target.value,
    }));
  };

  const handleInputChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    question: string
  ) => {
    setAnswers((prevState) => ({
      ...prevState,
      [question]: event.target.value,
    }));
  };

  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const isTablet = useMediaQuery(theme.breakpoints.between("sm", "md"));

  return (
    <Container
      style={{
        display: "flex",
        justifyContent: "flex-start",
        flexDirection: "column",
        color: "white",
        textAlign: "center",
        padding: isMobile ? "10px" : "20px",
        maxWidth: isMobile ? "100%" : "1075px",
        marginTop: 0,
      }}
    >
      <Typography
        variant="h4"
        gutterBottom
        color="white"
        pt={isMobile ? "25px" : "-10px"}
        fontWeight={600}
        fontSize={30}
      >
        Harness the power of AI with ByteSizedAI—effortlessly integrating into
        your workflow with drag-and-drop ease to enhance your product stack, all
        without AI expertise.
      </Typography>
    <Box sx={{maxWidth: isMobile ? "100%" : "900px", paddingLeft:isMobile?"0px":"60px"}}>
      <Box
        sx={{
          padding: isMobile ? 0 : 2,
          backgroundColor: "#000000",
          color: "#ffffff",
          borderRadius: 2,
        }}
      >
        <Typography
          variant="h5"
          paragraph
          color="white"
          pt={isMobile ? "20px" : "0px"}
          pl={isMobile?0:8}
          pr={isMobile?0:8}
          textAlign={"left"}
        >
          Answer a few quick questions to secure your spot on our exclusive
          waitlist and be among the first 100 beta users to try ByteSizedAI free
          for a month!
        </Typography>
        <Grid
          container
          spacing={isMobile ? 0 : 3}
          sx={{
            textAlign: isMobile ? "center" : "left",
            paddingLeft: isMobile ? 0 : 8,
            paddingRight: isMobile ? 0 : 8,
            pt: 1,
          }}
        >
          <Grid item xs={12}>
            <FormControl component="fieldset">
              <Typography variant="h6" color="white">
                Do you believe AI should be built into products from the start,
                rather than added later as an afterthought?
              </Typography>
              <RadioGroup
                value={answers.question1}
                onChange={(e) => handleRadioChange(e, "question1")}
                row
                sx={{ justifyContent: isMobile ? "center" : "flex-start" }}
              >
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      sx={{
                        color: "white",
                        "&.Mui-checked": { color: "#72FF13" },
                      }}
                    />
                  }
                  label="Yes"
                  sx={{ color: "white", marginRight: 3 }}
                />
                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      sx={{
                        color: "white",
                        "&.Mui-checked": { color: "#72FF13" },
                      }}
                    />
                  }
                  label="No"
                  sx={{ color: "white" }}
                />
              </RadioGroup>
            </FormControl>
            {questionErrors.question1 && (
              <Typography color="error" variant="body2">
                This question is required.
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <Typography variant="h6" color="white">
                Would you find value in a solution that lets you effortlessly
                add AI to your product stack without requiring AI expertise?
              </Typography>
              <RadioGroup
                value={answers.question2}
                onChange={(e) => handleRadioChange(e, "question2")}
                sx={{ justifyContent: isMobile ? "center" : "flex-start" }}
                row
              >
                <FormControlLabel
                  value="Yes"
                  control={
                    <Radio
                      sx={{
                        color: "white",
                        "&.Mui-checked": { color: "#72FF13" },
                      }}
                    />
                  }
                  label="Yes"
                  sx={{ color: "white", marginRight: 3 }}
                />
                <FormControlLabel
                  value="No"
                  control={
                    <Radio
                      sx={{
                        color: "white",
                        "&.Mui-checked": { color: "#72FF13" },
                      }}
                    />
                  }
                  label="No"
                  sx={{ color: "white" }}
                />
              </RadioGroup>
            </FormControl>
            {questionErrors.question2 && (
              <Typography color="error" variant="body2">
                This question is required.
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <FormControl component="fieldset" fullWidth>
              <Typography variant="h6" color="white">
                How much would you be willing to pay for such a solution on a
                monthly basis?
              </Typography>
              <RadioGroup
                value={answers.question3}
                onChange={(e) => handleRadioChange(e, "question3")}
                sx={{ justifyContent: isMobile ? "center" : "flex-start" }}
                row
              >
                <FormControlLabel
                  value="Pay-per-use"
                  control={
                    <Radio
                      sx={{
                        color: "white",
                        "&.Mui-checked": { color: "#72FF13" },
                      }}
                    />
                  }
                  label="Pay-per-use"
                  sx={{ color: "white", marginRight: 3 }}
                />
                <FormControlLabel
                  value="Pay for a license"
                  control={
                    <Radio
                      sx={{
                        color: "white",
                        "&.Mui-checked": { color: "#72FF13" },
                      }}
                    />
                  }
                  label="Pay for a license"
                  sx={{ color: "white" }}
                />
              </RadioGroup>
            </FormControl>
            {questionErrors.question3 && (
              <Typography color="error" variant="body2">
                This question is required.
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ width: "100%" }}>
              <TextField
                label="Where do you think a solution like this might fall short or face challenges?"
                variant="standard"
                value={answers.question4}
                onChange={(e) => handleInputChange(e, "question4")}
                fullWidth
                style={{
                  marginBottom: "10px",
                  backgroundColor: "transparent",
                }}
                InputProps={{
                  style: {
                    color: "white",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "white",
                  },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "#72FF13",
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: "#72FF13",
                  },
                }}
              />
            </Box>
            {questionErrors.question4 && (
              <Typography color="error" variant="body2">
                This question is required.
              </Typography>
            )}
          </Grid>

          <Grid item xs={12}>
            <Box sx={{ width: "100%" }}>
              <TextField
                label="What tools or methods are you currently using to meet these needs?"
                variant="standard"
                value={answers.question5}
                onChange={(e) => handleInputChange(e, "question5")}
                fullWidth
                style={{
                  marginBottom: "10px",
                  backgroundColor: "transparent",
                }}
                InputProps={{
                  style: {
                    color: "white",
                  },
                }}
                InputLabelProps={{
                  style: {
                    color: "white",
                  },
                }}
                sx={{
                  "& .MuiInput-underline:before": {
                    borderBottomColor: "#72FF13",
                  },
                  "& .MuiInput-underline:hover:before": {
                    borderBottomColor: "#72FF13",
                  },
                }}
              />
            </Box>
            {questionErrors.question5 && (
              <Typography color="error" variant="body2">
                This question is required.
              </Typography>
            )}
          </Grid>
        </Grid>
      </Box>

      <Box pl={isMobile ? 0 : 10} pr={isMobile ? 0 : 10}>
        <TextField
          label="Enter your email"
          variant="standard"
          value={email}
          onChange={handleEmailChange}
          error={emailError}
          helperText={emailError ? "Please enter a valid email" : ""}
          fullWidth
          style={{
            marginBottom: "0px",
            width: isMobile ? "100%" : "100%",
            backgroundColor: "transparent",
          }}
          InputProps={{
            style: {
              color: "white",
            },
          }}
          InputLabelProps={{
            style: {
              color: "white",
            },
          }}
          sx={{
            "& .MuiInput-underline:before": {
              borderBottomColor: "#72FF13",
            },
            "& .MuiInput-underline:hover:before": {
              borderBottomColor: "#72FF13",
            },
          }}
        />
        <FormControl
          variant="standard"
          fullWidth
          style={{
            marginBottom: "10px",
            width: isMobile ? "100%" : "100%",
            marginTop: isMobile ? "0px" : "30px",
          }}
        >
          <InputLabel
            id="role-select-label"
            style={{ color: "white", textAlign: "left", paddingLeft: "0px" }}
          >
            Select your role
          </InputLabel>
          <Select
            labelId="role-select-label"
            id="role-select"
            value={selectedRole}
            onChange={handleRoleChange}
            label="Select your role"
            sx={{
              color: "white",
              borderBottom: "1px solid #72FF13 !important",
              textAlign: "left",
              border: "none",
              "& .MuiSelect-icon": {
                color: "white",
              },
              "&.Mui-focused": {
                borderBottom: "1px solid #72FF13 !important",
                outline: "none",
              },
            }}
            MenuProps={{
              PaperProps: {
                style: {
                  backgroundColor: "#333",
                  color: "white",
                },
              },
            }}
          >
            {rolesItems.map((role) => (
              <MenuItem key={role} value={role}>
                {role}
              </MenuItem>
            ))}
          </Select>
          {roleError && (
            <Typography
              variant="body2"
              color="error"
              style={{
                marginTop: "5px",
                textAlign: "left",
                paddingLeft: "0px",
                fontSize: "0.75rem",
              }}
            >
              Please select a role
            </Typography>
          )}
        </FormControl>
      </Box>

      <Box
        style={{
          textAlign: "left",
          marginTop: "20px",
          display: "flex",
          flexDirection: "row",
          justifyContent: "space-between",
          width: isMobile ? "100%" : "81%",
          alignItems: "center",
        }}
        pl={isMobile ? 0 : 10}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={isChecked}
              onChange={(e) => setIsChecked(e.target.checked)}
              sx={{ color: "white", "&.Mui-checked": { color: "#72FF13" } }}
            />
          }
          label={
            <span style={{ color: "white" }}>Sign in for early access!</span>
          }
        />
        <Button
          onClick={handleSubmit}
          style={{
            color: "white",
            minWidth: "auto",
            padding: "6px",
            backgroundColor: "transparent",
            boxShadow: "none",
            marginRight: "0px",
          }}
        >
          Go
          <BsArrowUpRight style={{ marginLeft: "8px", fontSize: "16px" }} />
        </Button>
      </Box>
    </Box>  
    </Container>
  );
};

export default CallToActions;
